<template>
  <div>
    <h2>Fandom</h2>
    <p>Informationen über das Fandom...</p>
  </div>
</template>

<script>
export default {
  name: 'FandomPage'
};
</script>
