<template>
  <div class="impressum-container">
    <h1 class="impressum-title">Impressum</h1>
    <div class="contact-info">
      <p class="section-title">Kontakt:</p>
      <ul>
        <li class="contact-header">Email:</li>
        <ul>
          <li class="contact-detail">Allgemeine E-Mail: <a href="mailto:info@belkamc.de">info@belkamc.de</a></li>
          <li class="contact-detail">E-Mail zeyyco: <a href="mailto:zeyycoyt@gmail.de">zeyycoyt@gmail.de</a></li>
          <li class="contact-detail">E-Mail CGTV: <a href="mailto:...">...</a></li>
        </ul>
      </ul>
      <p class="section-title">Andere Kontakt Möglichkeiten:</p>
      <ul>
        <li class="contact-header">Discord:</li>
        <ul>
          <li class="contact-detail">Discord zeyyco: zeyyco</li>
          <li class="contact-detail">Discord CGTV: commandergreetv</li>
        </ul>
        <li class="contact-header">PS:</li>
        <ul>
          <li class="contact-detail">PS zeyyco: zeyyco</li>
          <li class="contact-detail">PS CGTV: hexeandthunder</li>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Impressum',
};
</script>

<style scoped>
.impressum-container {
  text-align: left;
  padding: 0 20px; /* Kein zusätzliches Padding oben */
  margin-top: 0; /* Kein zusätzliches Margin oben */
}

.impressum-title {
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  font-size: 2em;
  color: var(--title-color-light);
  text-shadow: -2px -2px 0 var(--title-shadow-light),  
               2px -2px 0 var(--title-shadow-light),
              -2px  2px 0 var(--title-shadow-light),
               2px  2px 0 var(--title-shadow-light);
}

.dark-mode .impressum-title {
  color: var(--title-color-dark);
  text-shadow: -2px -2px 0 var(--title-shadow-dark),  
               2px -2px 0 var(--title-shadow-dark),
              -2px  2px 0 var(--title-shadow-dark),
               2px  2px 0 var(--title-shadow-dark);
}

.contact-info {
  font-size: 1em;
  line-height: 1.5;
  color: var(--text-color-light);
  margin-top: 10px; /* Reduziert das Top-Margin für besseren Abstand */
}

.dark-mode .contact-info {
  color: var(--text-color-dark);
}

.section-title {
  font-size: 1.2em; /* Größer als der Rest des Textes */
  font-weight: bold;
  margin-top: 20px;
}

.contact-header {
  font-weight: bold; /* Dickere Schrift für die Hauptkategorien */
}

.contact-detail {
  font-weight: bold; /* Dickere Schrift für die Unterpunkte */
}

.contact-info a {
  color: var(--title-color-light);
  text-decoration: none;
}

.dark-mode .contact-info a {
  color: var(--title-color-dark);
}
</style>
