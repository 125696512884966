<template>
  <div class="team-container">
    <h1 class="team-title">Team</h1>

    <div class="role-section">
      <h2 class="role-title">Moderator (BMFS)</h2>

      <div class="team-member">
        <img src="@/assets/commandergreetv.png" alt="Commandergreetv" class="team-member-img" />
        <div class="team-member-info">
          <h3>Commandergreetv - Moderator (BMFS)</h3>
          <p>Commandergreetv ist einer der Gründer von BelkaMC. Er spielt eine zentrale Rolle bei der Gestaltung der Lore und ist maßgeblich am Erstellen der Karten beteiligt.</p>
        </div>
      </div>

      <div class="team-member">
        <img src="@/assets/zeyyco.png" alt="zeyyco" class="team-member-img" />
        <div class="team-member-info">
          <h3>zeyyco - Moderator (BMFS) / Dev</h3>
          <p>zeyyco übernimmt die technische Leitung des Servers sowie die Entwicklung und Pflege der zugehörigen Websites. Gemeinsam mit Commandergreetv verwaltet er das Projekt und trägt zur Lore bei.</p>
        </div>
      </div>
    </div>

    <div class="role-section">
      <h2 class="role-title">Helfer</h2>

      <div class="team-member">
        <img src="@/assets/nayanaru.png" alt="Nayanaru" class="team-member-img" />
        <div class="team-member-info">
          <h3>Nayanaru - Helfer</h3>
          <p>Nayanaru ist einer der Gründer von BelkaMC und unterstützt das Team insbesondere bei der Entwicklung der Lore.</p>
        </div>
      </div>

      <div class="team-member">
        <img src="@/assets/woldir777.png" alt="woldir777" class="team-member-img" />
        <div class="team-member-info">
          <h3>woldir777 - Helfer</h3>
          <p>woldir777 ist ebenfalls ein Gründungsmitglied und trägt zur Lore bei. Darüber hinaus unterstützt er das Team aktiv beim Bauen.</p>
        </div>
      </div>
    </div>

    <div class="role-section">
      <h2 class="role-title">VIP</h2>

      <div class="team-member">
        <img src="@/assets/barthinator.png" alt="Barthinator" class="team-member-img" />
        <div class="team-member-info">
          <h3>Barthinator - VIP</h3>
          <p>Barthinator unterstützt das Team gelegentlich und ist ein aktiver Teilnehmer im RolePlay.</p>
        </div>
      </div>

      <div class="team-member">
        <img src="@/assets/s7mon.png" alt="S7mon" class="team-member-img" />
        <div class="team-member-info">
          <h3>S7mon - VIP</h3>
          <p>S7mon ist ein langjähriger Spieler, der maßgeblich zur Entwicklung der Lore beigetragen hat.</p>
        </div>
      </div>

      <div class="team-member">
        <img src="@/assets/pixachux.png" alt="Pixachux" class="team-member-img" />
        <div class="team-member-info">
          <h3>Pixachux - VIP</h3>
          <p>Pixachux spielt den Präsidenten von Pixatown und unterstützt das Team bei der Entwicklung der Lore.</p>
        </div>
      </div>

      <div class="team-member">
        <img src="@/assets/nici.png" alt="Nici" class="team-member-img" />
        <div class="team-member-info">
          <h3>Nici - VIP</h3>
          <p>Nici ist ein Gründungsmitglied von BelkaMC, hat viel zur Lore beigetragen und ist seit Beginn des Projekts dabei.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Team',
};
</script>

<style scoped>
.team-container {
  padding: 20px;
}

.team-title {
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  font-size: 2em;
  color: var(--title-color-light);
  text-shadow: -2px -2px 0 var(--title-shadow-light),
               2px -2px 0 var(--title-shadow-light),
              -2px  2px 0 var(--title-shadow-light),
               2px  2px 0 var(--title-shadow-light);
  margin-bottom: 30px;
  text-align: left;
  margin-left: 20px;
}

.dark-mode .team-title {
  color: var(--title-color-dark);
  text-shadow: -2px -2px 0 var(--title-shadow-dark),
               2px -2px 0 var(--title-shadow-dark),
              -2px  2px 0 var(--title-shadow-dark),
               2px  2px 0 var(--title-shadow-dark);
}

.role-section {
  margin-bottom: 40px;
}

.role-title {
  font-size: 1.5em;
  color: var(--title-color-light);
  margin-bottom: 20px;
  margin-left: 20px;
  text-align: left;
}

.dark-mode .role-title {
  color: var(--title-color-dark);
}

.team-member {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}

.team-member-img {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  border-radius: 8px;
}

.team-member-info {
  max-width: 600px;
}

.team-member-info h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: var(--text-color-light);
}

.dark-mode .team-member-info h3 {
  color: var(--text-color-dark);
}

.team-member-info p {
  font-size: 1em;
  color: var(--text-color-light);
  line-height: 1.4;
}

.dark-mode .team-member-info p {
  color: var(--text-color-dark);
}
</style>
